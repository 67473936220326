import { Component } from '@angular/core';
import { NgClass, NgIf, NgFor, UpperCasePipe, DecimalPipe } from '@angular/common';

import { CoreService } from '../core.service'

@Component({
	selector: 'app-footer',
	standalone: true,
	imports: [ NgIf ],
	templateUrl: './footer.component.html',
	styleUrl: './footer.component.scss'
})
export class FooterComponent {
	constructor( public core: CoreService ){}

	exposeCore(){
		window['CONF']['core'] = this.core;
	}
}
