import { Component, inject, HostBinding } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { CoreService } from './core.service';

import { HeaderComponent } from './header/header.component';
import { StreamComponent } from './stream/stream.component';
import { ChatComponent } from './chat/chat.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { FooterComponent } from './footer/footer.component';


import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [
		HeaderComponent,
		StreamComponent, ChatComponent,
		ScheduleComponent,
		RouterOutlet,
		FooterComponent,

		MatSlideToggleModule
	],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss'
})
export class AppComponent {
	constructor( public core: CoreService ) {
		let theme = this.core.settings['theme_name'];
		if( theme )
			document.documentElement.classList.add( "theme-" + theme )
	}


	currentLayout: string = "default";
	@HostBinding('class.layout-default') get is_default(){
		return !this.core.settings.use_layout_theater
	}
	@HostBinding('class.layout-theater') get is_theater(){
		return this.core.settings.use_layout_theater
	}
	
}
