<button class="settings" (click)="core.redirectTo('/settings', true)">
	<i class="material-icons">settings</i>
	<small>Device</small>
	<small>Settings</small>
</button>

<button *ngIf="core.auth" class="profile" (click)="core.redirectTo('/top-rankings', true)">
	<img [src]="core.user.avatar" alt=""/>
	<small>Stats</small>
	<i *ngIf="core.is_premium" class="premium material-icons">verified</i>
</button>

<button *ngIf="!core.auth" class="profile" (click)="core.onAuthRequired.emit(null)">
	<img [src]="core.user.avatar" alt=""/>
	<small>Login</small>
</button>

<aside style="text-align: center;background: #171414;border-radius: 5px;padding: 5px;">
	PREMIUM<br/>
	UNDER CONSTRUCTION
</aside>

<!--div class="leaderboard" *ngIf="!leaderboardClosed && core.settings.show_leaderboard && (core.topPUsers.length + core.topSUsers.length > 0)">

	<div class="reverse-motion-screen"    [style.animation-duration.s]="mover.scrollWidth / (fastForwarding ? 200 : 20)">
		<div class="motion-screen" #mover [style.animation-duration.s]="mover.scrollWidth / (fastForwarding ? 200 : 20)" [class.rev]="leaderboardReversed">

			<section>
				<h1>Prediction Leaderboard</h1>
				<div class="user prediction" *ngFor="let u of core.topPUsers">
					<span class="info">
						<span class="rank">{{ u.prediction_rank }}</span>
						<i class="material-icons">{{ core.rank_of(u).icon }}</i>
						<span class="title"> {{ core.rank_of(u).title }}</span>
					</span>
					<small class="stats">
						<span class="name"><img *ngIf="u.avatar" [src]="u.avatar"/>{{ u.username }}</span>
						<span class="percentage">
							<span class="good-text">{{ u.total_right_predictions }}R</span><span class="bad-text">{{ u.total_wrong_predictions }}W</span>:{{ 100 * u.total_right_predictions / ( u.total_right_predictions + u.total_wrong_predictions ) | number:'0.0-2' }}%
						</span>
					</small>
				</div>
			</section>
			<section>
				<h1>Betting Simulator</h1>
				<div class="user simulator" *ngFor="let u of core.topSUsers; let i = index">
					<span class="rank" [ngClass]="'index_' + i">{{ u.bet_rank }}</span>
					<span class="name"><img *ngIf="u.avatar" [src]="u.avatar"/>{{ u.username }}</span>
					<span class="balance">${{ +u.balance + +u.total_unsettled | moneyPipe }}</span>
				</div>
			</section>

		</div>
	</div>

	<div class="controls">
		<i class="material-icons" (click)="leaderboardClosed=true">close</i>
		<i class="material-icons" (click)="leaderboardReversed=!leaderboardReversed">sync_alt</i>
	</div>

</div-->



















<div class="logo">
	<div>{{ core.top_domain.split('.')[0] | uppercase }}</div>
	<img [src]="core.logoPath" class="site-logo" [ngClass]="core.top_domain.split('.')[0]"/>
</div>
