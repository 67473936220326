<header>
	<app-header></app-header>
</header>

<main class="stream-wrapper">
	<app-stream></app-stream>
</main>

<aside class="chat-wrapper">
	<app-chat></app-chat>
</aside>

<section class="schedule-wrapper">
	<app-schedule></app-schedule>
</section>

<!--nav>
	<button class="route" routerLink="/" [class.selected]="core.url_path == '/'">
		Betting Simulator
		<span class="money-peek">${{ core.user.balance | moneyPipe }}</span>
		<span class="bottom-bookmark"></span>
	</button>

	<button class="route" routerLink="/top-rankings" [class.selected]="core.url_path == '/top-rankings'">
		Stats & History
		<span class="bottom-bookmark"></span>
	</button>

	<button class="route" routerLink="/time-machine" [class.selected]="core.url_path == '/time-machine'">
		Time Machine
		<span class="bottom-bookmark"></span>
	</button>
</nav-->

<section class="router-wrapper">
	<router-outlet></router-outlet>
</section>

<footer class="footer-wrapper">
	<app-footer></app-footer>
</footer>
