<header>
	@for(g of games; track g.id) {
	<div class="daterange">
		<span class="time-text">{{ g.datetime | date:'MMM dd':core.tz }}</span>
	</div>
	}
</header>
<div class="games">
	@for(g of games; track g.id) {
	<div class="game" [ngClass]="'status_' + g.status.toLowerCase()">
		<span class="time">{{ g.datetime | date:'HH:mm':core.tz }} (ET)</span>

		<span class="teams">
			@for( tid of g.teams; track tid ){
				@let team = core.teams[tid];
				<span class="team">
					<img [src]="team.logo" [alt]="team.abbreviation"/>
					<small>{{ team.abbreviation }}</small>
				</span>
			}
		</span>

	</div>
	}
</div>
<footer>
	<div class="clock">
		<span class="time-text">{{ core.clock_datetime | date:'MMM dd HH:mm:ss':core.tz }} (ET)</span>
	</div>
</footer>
