<header>
	<div class="title">
		<div class="icon-group live">
			<i class="container material-icons">tv</i>
		</div>

		<span> LIVE TV / <b>REPLAYS</b> </span>
	</div>

	<button class="selector" (click)="(streamMenuShown=!streamMenuShown) && (linkMenuShown=false)">
		<span>Select Game</span>
		<i class="material-icons" [innerText]="streamMenuShown ? 'close' : 'expand_more'"></i>
	</button>
</header>

<section class="stream-screen-wrapper">
	<img class="ratio" [src]="core.getPlacehoderVideo()" alt=""/>

	<div class="stream-screen">
		<!--app-player *ngFor="let conf of core.createRange(playerCount); let playerId=index" [playerId]="playerId" [streamComponent]="this"></app-player-->
	</div>
</section>

<footer>
	<aside class="layout">
	</aside>
</footer>
