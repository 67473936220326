import { Routes } from '@angular/router';

import { SettingsComponent } from './settings/settings.component';
import { SimulatorComponent } from './simulator/simulator.component';


export const routes: Routes = [
	{ path: 'settings', component: SettingsComponent },
	{ path: '**', component: SimulatorComponent },
];
