import { Component, HostBinding, ViewChild, OnInit, EventEmitter }  from '@angular/core';
import { NgClass, NgIf, NgFor, UpperCasePipe, DecimalPipe } from '@angular/common';
import { SafeUrl }    from '@angular/platform-browser';
import { Observable, of, pipe } from 'rxjs';
import { map, tap, retry } from 'rxjs/operators';


import { CoreService } from '../core.service';
import { PlayerComponent } from '../player/player.component';

var CONF = window['CONF'];

@Component({
	selector: 'app-stream',
	standalone: true,
	imports: [
		NgClass, NgIf, NgFor,
	],
	templateUrl: './stream.component.html',
	styleUrl: './stream.component.scss'
})
export class StreamComponent {
	constructor( public core: CoreService ){
		this.core.streamComponent = this;
	}


	//Handling PlayerComponents
	selectedPlayerIndex: number = null;
	playerCount: number = 1;
	players: PlayerComponent[] = new Array(this.playerCount);
	get sp() {
		if( this.selectedPlayerIndex === null ) return
		return  this.players[ this.selectedPlayerIndex ];
	}

	gameSelected(game, arg2){
		this.streamMenuShown = false;
		this.linkMenuShown   = false;

		let pref = null;
		if( arg2.target ){
			let t = arg2.target;
			if( t.classList.contains("away") || t.parentElement.classList.contains("away") )
				pref = "away"

			if( t.classList.contains("home") || t.parentElement.classList.contains("home") )
				pref = "home"

		} else pref = arg2.toLowerCase();

		let fs = this.orderedFeedsInGame[ game.id ];
		let selected_feed = fs[0];

		for( let f of fs )
			if( pref == f.title.toLowerCase() )
				{ selected_feed=f; break }

		let game_id = game.id;
		let feed_id = selected_feed.id;
		let link_id = selected_feed['link_ids'][0]; //Links are alreadysorted by "usefull" ness


		let flavor = false; //TODO: Make it make more readable!
		for( flavor of this.core.id2links[link_id]['flavors'] )
			if( this.core.is_premium ){
				if( flavor['premium'] )
					break;
			} else if( !flavor['premium'] )
				break;

		if( flavor['premium'] && !this.core.is_premium )
			flavor = false;


		//this.sp.assignLink( link_id, feed_id, flavor, game_id );
	}



	preferredFeedOrder: string[] = ["National", "Home", "Away", "French"];
	tagClassToText: any = {
		"espnplus":"ESPN+",
		"premium":"premium",
		"free":"free",
		"fr":"+french",
		"geolimit":"Geo Limited",
		"live":"live",
		"replay":"replay",
		"planned":"planned",
	};

	linkMenuShown:   boolean = false;
	streamMenuShown: boolean = false;
	orderedFeedsInGame: any = {}; // game_id -> feed[]
	gameidToOrder: any = {};// game_id -> number
	gameidToTags: any = {}; // game_id -> tag_class[]
	feedidToUrl:  any = {}; // feed_id -> url
	orderSplitCutoff = 13;
	orderSplitShown = false;

	streamGridView   = true;//this.core.settings['stream_grid_view'];
	streamSortByTime = true;//this.core.settings['stream_sort_by_time'];

	reorderStreams(){

		let order = 100;
		let autoSelect = null; //For autoload
		this.orderedFeedsInGame = {};
		this.orderSplitShown = false;
		//this.core.games
		for( let g of [] ){
			this.orderedFeedsInGame[g.id] = []

			let default_tag = 'planned'
			let tags = [];
			for( let cid of g.content_id_list ){
				let f = this.core.feeds[cid];
				if( !f ) continue

				this.orderedFeedsInGame[g.id].push( f )

				if( "REM".includes( f.status ) )
					default_tag = 'replay'

				for( let tag of f.tags )
					if( !tags.includes(tag) )
						tags.push(tag)

			}
			if( tags.length == 0 )
				tags.push( default_tag )

			this.gameidToTags[g.id] = tags;
			this.orderedFeedsInGame[g.id].sort((f1, f2)=>{
				return this.preferredFeedOrder.indexOf( f1.title ) - this.preferredFeedOrder.indexOf( f2.title )
			})

			if( this.streamSortByTime ){
				order -= 1;
			} else {
				order = 20 - (
					( this.core.settings['favourite_teams'][g.home_abr] || 0) +
					( this.core.settings['favourite_teams'][g.away_abr] || 0) +
					( tags.includes("live") ? 8 : 0 ) +
					( {"I":2, "P":1}[g.status] || 0  )
				)
			}

			this.gameidToOrder[g.id] = order;
			if( order > this.orderSplitCutoff )
				this.orderSplitShown = true;

			if( this.orderedFeedsInGame[g.id].length == 0 ) //Since empty arrays are considered truthy!
				this.orderedFeedsInGame[g.id] = null

			try {
				if( autoSelect ){
					if( autoSelect[0].status != "L" && g.status == "L" )
						autoSelect = [g, this.orderedFeedsInGame[g.id][0].title.toLowerCase()]

				} else autoSelect = [g, this.orderedFeedsInGame[g.id][0].title.toLowerCase()]
			}catch(e){}

		}

		let settings = this.core.settings;
		settings['stream_grid_view'] = this.streamGridView;
		settings['stream_sort_by_time'] = this.streamSortByTime;
		this.core.settings = settings;

		return autoSelect;
	}


	resetStream( source_id, type, event ){
		let btn = event.target;

		if( btn.classList.contains("loading") ) return;
		btn.classList.remove("success")
		btn.classList.remove("failure")
		btn.classList.add("loading")


		this.core.http.post<any>( `https://api.${this.core.top_domain}/api/profile/reload_server`,
			{ 'source_id':source_id, 'type': type },
			{ withCredentials: true }
		).subscribe( (success) => {
			btn.classList.remove("loading")
			btn.classList.add("success")

		}, (err)=>{
			btn.classList.remove("loading")
			btn.classList.add("failure")
		});
	}

	ngOnInit(){
		this.core.games_changed_event.subscribe(()=>{

			let autoSelect = this.reorderStreams();

			if(
				!this.core.autoPlayed &&
				this.core.settings['autoplay']==1 &&
				autoSelect
			){
				this.core.autoPlayed = true;
				this.gameSelected( autoSelect[0], autoSelect[1] )
			}

		})
	}




	//TEMP
	castInterval = null; castState = 0;
	turboActive = false; castButton = null;
	castStream = null;

}
