<small class="powered-by">Powered By: 24All Team <i class="material-icons" (click)="exposeCore()">favorite</i></small>
<a class="contact-us icon-text" href="mailto:{{core.contactMail}}" *ngIf="core.contactMail">
	<i class="material-icons">mail_outline</i>
	<small>email us</small>
</a>

<a class="version-history icon-text" routerLink="/devlog">
	<i class="material-icons">code</i>
	<small>devlog</small>
</a>

<a class="settings" (click)="core.redirectTo('/settings', true)">
	<i class="material-icons">settings</i>
	<small>settings</small>
</a>
