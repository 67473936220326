<header>
	<button class="cover" (click)="toggleChatCover()" [innerText]="chatCoverShown ? 'Show Chat' : 'Cover Chat'" *ngIf="!chatDisabled"></button>

	<button class="rules" (click)="toggleChatRules()" [innerText]="chatRulesShown ? 'Close Rules' : 'Show Rules'"></button>

	<button class="rankings" *ngIf="core.ranking_active" (click)="core.redirectTo('/top-rankings', true)">Show Rankings</button>
</header>

<aside [class.shown]="chatRulesShown">
	<header>CHAT RULES</header>
	<p class="rules">

		<span class="important">No Spoilers!</span>

		<span *ngIf="core.sport=='nhl'">English only in General chat. Please use French chat for French only. If you need help please use the Support chat.</span>
		<span *ngIf="core.sport!='nhl'">English only in chat.</span>

		<span>Don’t post links in Chat. Sharing of other streaming links is OK only when an <b style="color: #00EAF0">Admin</b> or <b style="color: #01CC00">Moderator</b> has given approval.</span>

		<span>Slurs of any kind regardless of the reason are not tolerated and will result in a permanent ban from chat.</span>

		<span>Sexual harassment, sexual innuendos and sexual jokes are not allowed in chat.</span>

		<span>Treat others with respect - in other words, remember the human.</span>

		<span>Use good judgment when picking non-sports conversation topics.</span>

		<span>Don't discuss politics, conspiracy theories, or other sensitive topics here. This site is for you to watch sports together and be around fellow sport fans.</span>

		<span>
			Don’t spam the chat. Cheering is okay, spamming nonsense is not.
			Note: This chat uses filters to transform foul language. Don't go around the chat's filters.
		</span>

		<span>
			The staff team's decisions are final. If a chat moderator tells you to do something, do it. If you have a problem with their decision,
			contact one of the admins on discord or at the email <a href="mailto:24ban.issues@gmail.com">24ban.issues&#64;gmail.com</a>
		</span>

	</p>
</aside>

<iframe
	[src]="chatSrc" *ngIf="!chatDisabled" #bungeechat id="bungeechat" [class.covered]="chatCoverShown || chatRulesShown" width="100%" allowtransparency="yes"
	allow="autoplay" frameborder="0" marginheight="0" marginwidth="0" scrolling="auto"></iframe>

<div class="logo" [class.covered]="chatCoverShown || chatDisabled">
	<img [src]="core.logoPath" class="site-logo" [ngClass]="core.top_domain.split('.')[0]"/>
	<button *ngIf="!chatDisabled" (click)="chatCoverShown=!(chatDisabled=true)">Close Chat</button>
	<small *ngIf="chatDisabled">Chat has been closed.</small>
</div>
